// Para la autenticación con Firebase
// 1ro - Asegurarse de crear un Realtime Database desde:
// Consola de Firebase > Realtime Database > Crear una base de datos (https://console.firebase.google.com/project)
// 2do - Obtener los parámetros de configuración desde:
// Consola de Firebase > Configuración > General > Agregar una app (Aplicación web) (https://console.firebase.google.com/project)
export const firebaseConfig = {
    apiKey: "AIzaSyAWdG4OJF6KTJNmuTAD6wjWGnsRdkxUfEc",
    authDomain: "educonnect-aleman.firebaseapp.com",
    databaseURL: "https://educonnect-aleman-default-rtdb.firebaseio.com",
    projectId: "educonnect-aleman",
    storageBucket: "educonnect-aleman.appspot.com",
    messagingSenderId: "7831808463",
    appId: "1:7831808463:web:8ce6a55b99faaa4bfb0aba",
  };
  
  // Para el envío de notificaciones push
  // Consola de Firebase > Configuración > Cloud Messaging > Certificados push web > Generate key pair (https://console.firebase.google.com/project)
  export const PUBLIC_VAPID_KEY = 'BG9wuEOScsq88QE1JhVEHKpJXHcbPgqPeXBOrtOet05qAxTHkiNV3AQEWJl4jSl4EaDXYwurhrbj9STPdPA9p1E';
  
  // Para acceder a los archivos almacenados en Google Cloud Storage (la misma que se creó para el server)
  // Consola de Firebase > Storage > Comenzar (https://console.firebase.google.com/project)
  //      - Paso 1 Utilizar las reglas de seguridad por defecto
  //      - Paso 2 Seleccionar la ubicación geográfica deonde se almacenarán los datos
  //
  //      gs://escuelas-manqa-localhost.appspot.com
  //
  // NOTA.- Al hacer esto se creará un bucket (escuelas-manqa-localhost.appspot.com)
  //        en Google Cloud Storage (https://console.cloud.google.com/storage/browser)
  //
  // URL pública = https://storage.googleapis.com
  // URL privada = https://storage.cloud.google.com
  export const BUCKET_NAME = 'educonnect-aleman.appspot.com';
  export const STORAGE_URL = `https://storage.googleapis.com/${BUCKET_NAME}`;